/*------------------------------------*\
    #ATOMS
\*------------------------------------*/



/*------------------------------------*\
  #GLOBAL
\*------------------------------------*/


/**
 * Global > colors
 */

.bg-black {
  background-color: $black;
}

.bg-grey {
  background-color: $grey;
}

.bg-white {
  background-color: $white;
}


/**
 * Global > animations
 */

.animate-fade {
  transition: opacity 0s;
  opacity: 1;
  &:hover {
    opacity: 0;
  }
}


/**
 * Global > visibilty
 */

// Completely remove from the flow and screen readers.
.is-hidden {
  display: none !important;
  visibility: hidden !important;
}

// Completely remove from the flow but leave available to screen readers.
.is-vishidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

// Display Classes
.hide-small {
  @media all and (max-width: $screen-sm-max) {
    display: none;
  }
}

.hide-med {
  @media all and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    display: none;
  }
}

.hide-large {
  @media all and (min-width: $screen-lg-min) {
    display: none;
  }
}



/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/

.button {
  display: inline-block;
  background-color: $body-color;
  border: $border-width-sm solid $base-color;
  color: $base-color;
  padding: .5em .8em .45em;
  &:hover {
    background-color: $grey;
    color: $base-color;
  }
}

.button--alt {
  background-color: $grey;
  border: $border-width-sm solid $base-color;
  color: $base-color;
  &:hover {
    background-color: $body-color;
    color: $base-color;
  }
}



/*------------------------------------*\
  #IMAGES
\*------------------------------------*/


img, video, object {
  max-width: 100%;
  height: auto;
}

img{
  vertical-align: middle;
}
