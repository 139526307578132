@charset "UTF-8";
/*------------------------------------*    #IMPORTS PRE_RENDER — Must be after reset
\*------------------------------------*/
/* line 4, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_reset.scss */
*, *:before, *:after {
  box-sizing: border-box; }

/* line 6, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_reset.scss */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

/* line 7, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_reset.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* line 8, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_reset.scss */
caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

/* line 9, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_reset.scss */
q, blockquote {
  quotes: none; }

/* line 10, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_reset.scss */
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

/* line 11, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_reset.scss */
a img {
  border: none; }

/* line 12, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_reset.scss */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block; }

/* line 18, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_reset.scss */
button, input {
  line-height: normal; }

/* line 19, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_reset.scss */
button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

/* line 20, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_reset.scss */
button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  *overflow: visible; }

/* line 21, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_reset.scss */
button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/* line 22, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_reset.scss */
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/*------------------------------------*    #COLOURS
\*------------------------------------*/
/*------------------------------------*    #BREAKPOINTS
\*------------------------------------*/
/*------------------------------------*    #SPACES
\*------------------------------------*/
/*------------------------------------*    #BORDERS WIDTH
\*------------------------------------*/
/*------------------------------------*    #GLOBAL TYPOGRAPHY
\*------------------------------------*/
@font-face {
  font-family: 'FolioOffice';
  src: url("../fonts/folio_book-webfont.eot");
  src: url("../fonts/folio_book-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/folio_book-webfont.woff") format("woff"), url("../fonts/folio_book-webfont.ttf") format("truetype"), url("../fonts/folio_book-webfont.svg#FolioBook") format("svg");
  font-weight: normal;
  font-style: normal; }

/*------------------------------------*    #FONTS
\*------------------------------------*/
/* line 20, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
.font-body, em,
i, b,
strong, em > b,
b > em,
i > b,
b > i,
em > strong,
strong > em,
i > strong,
strong > i, .form legend, .sg-pattern-category-title a,
.sg-pattern-head .sg-pattern-title a, body {
  font-family: "FolioOffice", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal; }

/*------------------------------------*    #TYPESIZE/SCALE
\*------------------------------------*/
/*------------------------------------*    #FONTSIZES
\*------------------------------------*/
/*------------------------------------*    #CORE TYPOGRAPHY
\*------------------------------------*/
/* line 57, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 14px;
  line-height: 1.35;
  text-transform: uppercase; }

/* line 67, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
h3 + p,
h3 + ul,
ul + p {
  margin-top: .5em; }

/* line 72, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
p + h3,
ul + h3 {
  margin-top: 1em; }

/* line 77, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
p {
  font-size: 14px;
  line-height: 1.35;
  margin-bottom: .5em; }

/* line 82, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
blockquote p {
  text-align: center; }

/* line 86, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
a {
  color: #000;
  border-bottom: 1px solid #000;
  padding-bottom: 1px;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.35; }
  /* line 96, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_mixins.scss */
  a:hover, a:focus, a:active {
    color: #000;
    border-bottom: 1px solid transparent; }

/* line 99, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
time {
  font-size: 14px;
  line-height: 1.35; }

/* line 124, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
p sub, p sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

/* line 131, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
p sup {
  top: -0.5em; }

/* line 135, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
p sub {
  bottom: -0.25em; }

/* line 139, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
p small {
  font-size: 14px;
  line-height: 1.35; }

/* line 143, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
p abbr {
  border-bottom: 1px dotted #000;
  cursor: help; }

/* line 150, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
p q:before,
p cite:before {
  content: '"'; }

/* line 153, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
p q:after,
p cite:after {
  content: '"'; }

/* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
ul:not(.nav) li {
  position: relative;
  padding-left: 1.4em; }
  /* line 162, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
  ul:not(.nav) li:before {
    content: "-";
    position: absolute;
    left: 0; }

/* line 170, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
p mark {
  background-color: rgba(0, 0, 0, 0.2);
  color: #000; }

/* line 175, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
p code,
p samp {
  font-family: monospace;
  font-size: 14px;
  line-height: 1.35; }

/* line 181, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
pre {
  font-family: monospace;
  font-size: 14px;
  line-height: 1.35; }

/* line 186, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
hr {
  background: #000;
  height: 1px;
  border: none;
  margin: 0; }

/* line 193, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
figcaption {
  font-size: 14px;
  line-height: 1.35;
  margin-top: 15px; }

/* line 199, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
dl dt {
  font-size: 14px;
  line-height: 1.35;
  margin-bottom: 15px; }
  /* line 202, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
  dl dt:first-of-type {
    padding-top: 20px;
    border-top: 1px #000 solid; }

/* line 207, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
dl dd {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px #000 solid; }

/* line 214, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
ol, ul, pre, dl {
  font-size: 14px;
  line-height: 1.35; }

/* line 215, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
ul, ol {
  list-style-type: none; }

/* line 216, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
ul ul {
  font-size: 14px;
  line-height: 1.35; }

/* line 217, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
ol ol {
  font-size: 14px;
  line-height: 1.35; }

/* line 218, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
.text ul {
  list-style-position: outside;
  list-style-type: disc;
  margin-left: 1em; }

/* line 219, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
.text ol {
  list-style-position: outside;
  list-style-type: decimal;
  margin-left: 1em; }

/* line 220, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
.text > ul,
.text > ol {
  margin-bottom: 1em; }

/* line 222, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
li {
  line-height: inherit; }

/* line 223, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_typography.scss */
input, textarea, label, select {
  line-height: 1.42857; }

/* line 6, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
label, legend, select {
  display: block; }

/* line 10, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

/* line 15, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
textarea {
  overflow: auto;
  vertical-align: top; }

/* line 21, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  *margin-top: 0;
  line-height: normal;
  cursor: pointer; }

/* line 30, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
  width: auto; }

/* line 40, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
select,
input[type="file"] {
  *margin-top: 4px;
  /* In IE7, the height of the select element cannot be changed by height, only font-size */ }

/* line 45, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
select:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

/* line 55, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
.form-control {
  transition: border-color 0.3s;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  box-shadow: none;
  padding: 6px; }
  /* line 62, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
  .form-control:focus {
    border-color: #9ACCE2;
    box-shadow: none; }
  /* line 66, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
  .form-control:hover {
    border-color: #CCC; }

/* line 71, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
select.form-control {
  appearance: none;
  transition: border-color 0.3s;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  vertical-align: middle;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE1LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iMCAwIDIwIDIwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMCAyMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+Cjxwb2x5Z29uIHBvaW50cz0iMTAsMTEuMiA2LjQsNy42IDQuMyw3LjYgMTAsMTMuMyAxNS43LDcuNiAxMy42LDcuNiAiLz4KPC9zdmc+Cg==);
  background-position: right 7px center;
  background-repeat: no-repeat;
  background-size: auto 16px;
  max-width: 100%; }
  /* line 84, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
  select.form-control:hover {
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.16);
    border-color: #999999; }

/* line 90, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
label {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase; }
  /* line 94, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
  .checkbox label, .radio label {
    font-size: 14px;
    line-height: 1.7; }

/*------------------------------------*  #FORMS
\*------------------------------------*/
/* line 107, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
.form legend {
  font-size: 14px;
  line-height: 1.35;
  text-align: center;
  color: #000;
  border: none;
  margin-bottom: 15px; }

@media (min-width: 800px) {
  /* line 115, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
  .form .inline-container {
    display: flex;
    align-items: stretch; } }

/* line 121, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
.form input {
  flex: 1 auto;
  height: 100%;
  border: 1px solid #000;
  padding: 15px;
  border-radius: 0;
  font-size: 14px;
  line-height: 1.35; }
  /* line 128, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
  .form input:focus {
    outline: none;
    border: 1px solid #000; }
  /* line 132, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
  .form input:hover {
    border: 1px solid #000; }
  @media (max-width: 799px) {
    /* line 121, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
    .form input {
      width: 100%; } }

/* line 139, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
.form .button {
  font-size: 14px;
  line-height: 1.35;
  white-space: nowrap;
  min-width: 150px; }
  @media (max-width: 799px) {
    /* line 139, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
    .form .button {
      width: 100%;
      margin-top: -1px; } }
  @media (min-width: 800px) {
    /* line 139, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_forms.scss */
    .form .button {
      margin-left: -1px; } }

/* line 10, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid.scss */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 1.5%;
  padding-right: 1.5%; }
  /* line 6, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_mixins.scss */
  .container:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 800px) {
    /* line 10, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid.scss */
    .container {
      width: calc(720px + $grid-gutter-width); } }
  @media (min-width: 1100px) {
    /* line 10, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid.scss */
    .container {
      width: calc(940px + $grid-gutter-width); } }
  @media (min-width: 1500px) {
    /* line 10, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid.scss */
    .container {
      width: calc(1140px + $grid-gutter-width); } }

/* line 30, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid.scss */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 1.5%;
  padding-right: 1.5%; }
  /* line 6, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_mixins.scss */
  .container-fluid:after {
    content: "";
    display: table;
    clear: both; }

/* line 39, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid.scss */
.row {
  margin-left: -1.5%;
  margin-right: -1.5%; }
  /* line 6, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_mixins.scss */
  .row:after {
    content: "";
    display: table;
    clear: both; }

/* line 135, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 1.5%;
  padding-right: 1.5%; }

/* line 151, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

/* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-1 {
  width: 8.33333%; }

/* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-2 {
  width: 16.66667%; }

/* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-3 {
  width: 25%; }

/* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-4 {
  width: 33.33333%; }

/* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-5 {
  width: 41.66667%; }

/* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-6 {
  width: 50%; }

/* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-7 {
  width: 58.33333%; }

/* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-8 {
  width: 66.66667%; }

/* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-9 {
  width: 75%; }

/* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-10 {
  width: 83.33333%; }

/* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-11 {
  width: 91.66667%; }

/* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-12 {
  width: 100%; }

/* line 179, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-0 {
  right: auto; }

/* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-1 {
  right: 8.33333%; }

/* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-2 {
  right: 16.66667%; }

/* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-3 {
  right: 25%; }

/* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-4 {
  right: 33.33333%; }

/* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-5 {
  right: 41.66667%; }

/* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-6 {
  right: 50%; }

/* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-7 {
  right: 58.33333%; }

/* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-8 {
  right: 66.66667%; }

/* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-9 {
  right: 75%; }

/* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-10 {
  right: 83.33333%; }

/* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-11 {
  right: 91.66667%; }

/* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-12 {
  right: 100%; }

/* line 169, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-push-0 {
  left: auto; }

/* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-push-1 {
  left: 8.33333%; }

/* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-push-2 {
  left: 16.66667%; }

/* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-push-3 {
  left: 25%; }

/* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-push-4 {
  left: 33.33333%; }

/* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-push-5 {
  left: 41.66667%; }

/* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-push-6 {
  left: 50%; }

/* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-push-7 {
  left: 58.33333%; }

/* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-push-8 {
  left: 66.66667%; }

/* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-push-9 {
  left: 75%; }

/* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-push-10 {
  left: 83.33333%; }

/* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-push-11 {
  left: 91.66667%; }

/* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-push-12 {
  left: 100%; }

/* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-0 {
  margin-left: 0%; }

/* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-1 {
  margin-left: 8.33333%; }

/* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-2 {
  margin-left: 16.66667%; }

/* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-3 {
  margin-left: 25%; }

/* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-4 {
  margin-left: 33.33333%; }

/* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-5 {
  margin-left: 41.66667%; }

/* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-6 {
  margin-left: 50%; }

/* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-7 {
  margin-left: 58.33333%; }

/* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-8 {
  margin-left: 66.66667%; }

/* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-9 {
  margin-left: 75%; }

/* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-10 {
  margin-left: 83.33333%; }

/* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-11 {
  margin-left: 91.66667%; }

/* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-12 {
  margin-left: 100%; }

@media (min-width: 800px) {
  /* line 151, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-1 {
    width: 8.33333%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-2 {
    width: 16.66667%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-3 {
    width: 25%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-4 {
    width: 33.33333%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-5 {
    width: 41.66667%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-6 {
    width: 50%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-7 {
    width: 58.33333%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-8 {
    width: 66.66667%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-9 {
    width: 75%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-10 {
    width: 83.33333%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-11 {
    width: 91.66667%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-12 {
    width: 100%; }
  /* line 179, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-0 {
    right: auto; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-1 {
    right: 8.33333%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-2 {
    right: 16.66667%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-3 {
    right: 25%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-4 {
    right: 33.33333%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-5 {
    right: 41.66667%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-6 {
    right: 50%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-7 {
    right: 58.33333%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-8 {
    right: 66.66667%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-9 {
    right: 75%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-10 {
    right: 83.33333%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-11 {
    right: 91.66667%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-12 {
    right: 100%; }
  /* line 169, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-0 {
    left: auto; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-1 {
    left: 8.33333%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-2 {
    left: 16.66667%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-3 {
    left: 25%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-4 {
    left: 33.33333%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-5 {
    left: 41.66667%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-6 {
    left: 50%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-7 {
    left: 58.33333%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-8 {
    left: 66.66667%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-9 {
    left: 75%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-10 {
    left: 83.33333%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-11 {
    left: 91.66667%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-12 {
    left: 100%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-0 {
    margin-left: 0%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-1 {
    margin-left: 8.33333%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-2 {
    margin-left: 16.66667%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-3 {
    margin-left: 25%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-4 {
    margin-left: 33.33333%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-5 {
    margin-left: 41.66667%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-6 {
    margin-left: 50%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-7 {
    margin-left: 58.33333%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-8 {
    margin-left: 66.66667%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-9 {
    margin-left: 75%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-10 {
    margin-left: 83.33333%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-11 {
    margin-left: 91.66667%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1100px) {
  /* line 151, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-1 {
    width: 8.33333%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-2 {
    width: 16.66667%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-3 {
    width: 25%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-4 {
    width: 33.33333%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-5 {
    width: 41.66667%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-6 {
    width: 50%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-7 {
    width: 58.33333%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-8 {
    width: 66.66667%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-9 {
    width: 75%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-10 {
    width: 83.33333%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-11 {
    width: 91.66667%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-12 {
    width: 100%; }
  /* line 179, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-0 {
    right: auto; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-1 {
    right: 8.33333%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-2 {
    right: 16.66667%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-3 {
    right: 25%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-4 {
    right: 33.33333%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-5 {
    right: 41.66667%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-6 {
    right: 50%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-7 {
    right: 58.33333%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-8 {
    right: 66.66667%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-9 {
    right: 75%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-10 {
    right: 83.33333%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-11 {
    right: 91.66667%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-12 {
    right: 100%; }
  /* line 169, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-push-0 {
    left: auto; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-push-1 {
    left: 8.33333%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-push-2 {
    left: 16.66667%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-push-3 {
    left: 25%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-push-4 {
    left: 33.33333%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-push-5 {
    left: 41.66667%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-push-6 {
    left: 50%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-push-7 {
    left: 58.33333%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-push-8 {
    left: 66.66667%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-push-9 {
    left: 75%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-push-10 {
    left: 83.33333%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-push-11 {
    left: 91.66667%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-push-12 {
    left: 100%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-0 {
    margin-left: 0%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-1 {
    margin-left: 8.33333%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-2 {
    margin-left: 16.66667%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-3 {
    margin-left: 25%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-4 {
    margin-left: 33.33333%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-5 {
    margin-left: 41.66667%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-6 {
    margin-left: 50%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-7 {
    margin-left: 58.33333%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-8 {
    margin-left: 66.66667%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-9 {
    margin-left: 75%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-10 {
    margin-left: 83.33333%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-11 {
    margin-left: 91.66667%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1500px) {
  /* line 151, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-1 {
    width: 8.33333%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-2 {
    width: 16.66667%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-3 {
    width: 25%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-4 {
    width: 33.33333%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-5 {
    width: 41.66667%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-6 {
    width: 50%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-7 {
    width: 58.33333%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-8 {
    width: 66.66667%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-9 {
    width: 75%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-10 {
    width: 83.33333%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-11 {
    width: 91.66667%; }
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-12 {
    width: 100%; }
  /* line 179, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-0 {
    right: auto; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-1 {
    right: 8.33333%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-2 {
    right: 16.66667%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-3 {
    right: 25%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-4 {
    right: 33.33333%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-5 {
    right: 41.66667%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-6 {
    right: 50%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-7 {
    right: 58.33333%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-8 {
    right: 66.66667%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-9 {
    right: 75%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-10 {
    right: 83.33333%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-11 {
    right: 91.66667%; }
  /* line 174, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-12 {
    right: 100%; }
  /* line 169, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-0 {
    left: auto; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-1 {
    left: 8.33333%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-2 {
    left: 16.66667%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-3 {
    left: 25%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-4 {
    left: 33.33333%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-5 {
    left: 41.66667%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-6 {
    left: 50%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-7 {
    left: 58.33333%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-8 {
    left: 66.66667%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-9 {
    left: 75%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-10 {
    left: 83.33333%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-11 {
    left: 91.66667%; }
  /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-12 {
    left: 100%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-0 {
    margin-left: 0%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-1 {
    margin-left: 8.33333%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-2 {
    margin-left: 16.66667%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-3 {
    margin-left: 25%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-4 {
    margin-left: 33.33333%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-5 {
    margin-left: 41.66667%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-6 {
    margin-left: 50%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-7 {
    margin-left: 58.33333%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-8 {
    margin-left: 66.66667%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-9 {
    margin-left: 75%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-10 {
    margin-left: 83.33333%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-11 {
    margin-left: 91.66667%; }
  /* line 184, /Users/antoine/Repo/dev-officekgdvs/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-12 {
    margin-left: 100%; } }

/*------------------------------------*    #PATTERNLAB Layout (à supprimer en production)
\*------------------------------------*/
/* line 6, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-00-temp-patternlab.scss */
.sg-viewport [role=main] {
  padding: 0.5em; }

/* line 11, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-00-temp-patternlab.scss */
.sg-main {
  width: 94%;
  margin: auto; }

/* line 16, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-00-temp-patternlab.scss */
.sg-colors {
  padding-left: 0;
  margin-bottom: 20px;
  font-size: 14px; }
  /* line 20, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-00-temp-patternlab.scss */
  .sg-colors li {
    width: 10em !important; }
  /* line 21, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-00-temp-patternlab.scss */
  .sg-colors .sg-swatch {
    height: 9em; }

/* line 26, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-00-temp-patternlab.scss */
.sg-pattern-category-title a,
.sg-pattern-head .sg-pattern-title a {
  font-size: 14px;
  line-height: 1.35;
  color: #000 !important;
  text-transform: uppercase; }

/* line 34, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-00-temp-patternlab.scss */
.sg-pattern-head .sg-pattern-title a {
  border: none; }

/* line 38, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-00-temp-patternlab.scss */
.demo-animate {
  border-radius: 0 !important;
  background-color: #CCC !important; }

/*! Flickity v2.0.4
http://flickity.metafizzy.co
---------------------------------------------- */
/* line 5, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-enabled {
  position: relative; }

/* line 9, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-enabled:focus {
  outline: none; }

/* line 11, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

/* line 17, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
/* line 25, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 34, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

/* line 40, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- previous/next buttons ---- */
/* line 47, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

/* line 62, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-prev-next-button:hover {
  background: white; }

/* line 64, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F; }

/* line 69, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-prev-next-button:active {
  opacity: 0.6; }

/* line 73, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-prev-next-button.previous {
  left: 10px; }

/* line 74, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
/* line 76, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

/* line 80, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

/* line 85, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto; }

/* line 90, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* line 98, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-prev-next-button .arrow {
  fill: #333; }

/* ---- page dots ---- */
/* line 104, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

/* line 115, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-rtl .flickity-page-dots {
  direction: rtl; }

/* line 117, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

/* line 128, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-page-dots .dot.is-selected {
  opacity: 1; }

/* line 133, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.flickity-slider {
  transform: none !important; }

/* line 136, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
.item {
  left: 0 !important;
  opacity: 0;
  z-index: -1; }
  /* line 140, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_00-01-flickity.scss */
  .item.is-selected {
    opacity: 1;
    z-index: 0; }

/*------------------------------------*    #ATOMS
\*------------------------------------*/
/*------------------------------------*  #GLOBAL
\*------------------------------------*/
/**
 * Global > colors
 */
/* line 16, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_01-atoms.scss */
.bg-black {
  background-color: #000; }

/* line 20, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_01-atoms.scss */
.bg-grey {
  background-color: #CCC; }

/* line 24, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_01-atoms.scss */
.bg-white {
  background-color: #FFFFFF; }

/**
 * Global > animations
 */
/* line 33, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_01-atoms.scss */
.animate-fade {
  transition: opacity 0s;
  opacity: 1; }
  /* line 36, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_01-atoms.scss */
  .animate-fade:hover {
    opacity: 0; }

/**
 * Global > visibilty
 */
/* line 47, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_01-atoms.scss */
.is-hidden {
  display: none !important;
  visibility: hidden !important; }

/* line 53, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_01-atoms.scss */
.is-vishidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px); }

@media all and (max-width: 1099px) {
  /* line 64, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_01-atoms.scss */
  .hide-small {
    display: none; } }

@media all and (min-width: 1100px) and (max-width: 1499px) {
  /* line 70, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_01-atoms.scss */
  .hide-med {
    display: none; } }

@media all and (min-width: 1500px) {
  /* line 76, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_01-atoms.scss */
  .hide-large {
    display: none; } }

/*------------------------------------*  #BUTTONS
\*------------------------------------*/
/* line 88, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_01-atoms.scss */
.button {
  display: inline-block;
  background-color: #FFFFFF;
  border: 1px solid #000;
  color: #000;
  padding: .5em .8em .45em; }
  /* line 94, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_01-atoms.scss */
  .button:hover {
    background-color: #CCC;
    color: #000; }

/* line 100, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_01-atoms.scss */
.button--alt {
  background-color: #CCC;
  border: 1px solid #000;
  color: #000; }
  /* line 104, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_01-atoms.scss */
  .button--alt:hover {
    background-color: #FFFFFF;
    color: #000; }

/*------------------------------------*  #IMAGES
\*------------------------------------*/
/* line 117, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_01-atoms.scss */
img, video, object {
  max-width: 100%;
  height: auto; }

/* line 122, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_01-atoms.scss */
img {
  vertical-align: middle; }

/*------------------------------------*  #MOLECULES
\*------------------------------------*/
/*------------------------------------*  #ITEM
\*------------------------------------*/
/* line 11, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.multi-gallery,
.gallery-double {
  position: relative;
  padding-top: 125px; }
  @media (max-width: 799px) {
    /* line 11, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
    .multi-gallery,
    .gallery-double {
      padding-top: 40px;
      padding-bottom: 30px;
      margin-bottom: 30px; } }
  @media (max-width: 799px) {
    /* line 11, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
    .multi-gallery,
    .gallery-double {
      border-bottom: 1px solid #000; } }

/* line 25, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.gallery-double {
  border: none; }
  @media (max-width: 799px) {
    /* line 25, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
    .gallery-double {
      border: none;
      padding-top: 50px; } }
  /* line 31, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .gallery-double .flickity-viewport {
    overflow: visible; }

@media (min-width: 800px) {
  /* line 36, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .multi-gallery .gallery,
  .gallery-double .item {
    height: calc(100vh - 90px - 75px - 28px * 3); } }

/* line 43, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.description__header,
.item__link {
  text-align: center; }
  @media (min-width: 800px) {
    /* line 43, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
    .description__header,
    .item__link {
      position: absolute;
      bottom: -45px;
      z-index: 9;
      left: 50%;
      transform: translateX(-50%);
      padding: .3em .3em 0 .3em; } }

@media (min-width: 800px) {
  /* line 56, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .multi-gallery img,
  .gallery-double .item .row {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    height: auto;
    max-height: 100%; } }

@media (max-width: 799px) {
  /* line 56, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .multi-gallery img,
  .gallery-double .item .row {
    height: auto; } }

/* line 72, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.gallery-double .item .row {
  height: 100%; }
  @media (min-width: 800px) {
    /* line 72, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
    .gallery-double .item .row {
      display: flex;
      align-items: center; } }

/* line 81, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.gallery {
  position: relative; }
  /* line 83, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .multi-gallery .gallery {
    text-align: center; }
    @media (max-width: 799px) {
      /* line 83, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
      .multi-gallery .gallery {
        padding-bottom: 20px;
        margin-bottom: 20px; } }
  @media (max-width: 799px) {
    /* line 91, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
    .gallery .item {
      display: block;
      width: 100%;
      height: auto; } }

/* line 99, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.gallery-double .gallery:not(.single) .element--image,
.multi-gallery .gallery:not(.single) .item {
  cursor: pointer; }

/* line 105, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.gallery-wrapper:not(.gallery-double) .item img {
  transition: opacity .3s ease-in-out; }
  /* line 107, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .gallery-wrapper:not(.gallery-double) .item img.lazy-loaded {
    opacity: 1; }
  /* line 110, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .gallery-wrapper:not(.gallery-double) .item img:not(.lazy-loaded) {
    opacity: 0; }

@media (min-width: 800px) {
  /* line 115, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .news__figure img {
    transition: opacity .3s ease-in-out; }
    /* line 117, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
    .news__figure img.lazy-loaded {
      opacity: 1; }
    /* line 120, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
    .news__figure img:not(.lazy-loaded) {
      opacity: 0; } }

/**
 * item > multi-gallery
 */
/* line 131, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.multi-gallery > .row {
  position: relative; }

/* line 136, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.multi-gallery .item {
  overflow: hidden; }
  @media (max-width: 799px) {
    /* line 136, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
    .multi-gallery .item {
      padding-bottom: 15px;
      height: calc(100vh - 220px); }
      /* line 141, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
      .multi-gallery .item img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%); } }
  @media (min-width: 800px) {
    /* line 136, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
    .multi-gallery .item {
      width: 100%;
      height: 100%; } }

@media (min-width: 500px) and (max-width: 1099px) {
  /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .gallery-double .item > .row {
    display: flex; }
  /* line 162, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .gallery-double .item__element {
    width: 50%;
    height: calc(100vh - 140px) !important; }
    /* line 165, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
    .gallery-double .item__element img {
      position: relative;
      top: 50%;
      transform: translateY(-50%); } }

/**
 * item > gallery-double
 */
/* line 178, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.gallery-double .item {
  width: 100%;
  cursor: auto; }
  /* line 181, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .gallery-double .item .item__element {
    text-align: center;
    position: relative;
    height: 100%; }
    @media (max-width: 799px) {
      /* line 181, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
      .gallery-double .item .item__element {
        padding-bottom: 15px;
        height: calc(50vh - 100px); }
        /* line 188, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
        .gallery-double .item .item__element img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto; } }
  @media (min-width: 800px) {
    /* line 196, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
    .gallery-double .item img {
      max-width: 100%;
      width: auto;
      max-height: 100%;
      height: auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%); } }
  /* line 208, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .gallery-double .item .item__link .link__button {
    cursor: pointer;
    display: inline-block; }
  /* line 213, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .gallery-double .item h3 + p {
    margin-top: 0; }
  /* line 216, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .gallery-double .item .news__content h2 + h3 {
    margin-bottom: 0; }

@media (max-width: 799px) {
  /* line 222, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .flickity-slider {
    display: flex;
    align-items: center; } }

/* line 228, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.gallery-status {
  font-size: 14px;
  line-height: 1.35;
  position: absolute;
  bottom: -25px; }
  @media (min-width: 800px) {
    /* line 233, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
    .gallery--first .gallery-status {
      left: 3%; }
    /* line 236, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
    .gallery--last .gallery-status {
      right: 3%; } }

/* line 242, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.news__content {
  max-width: 500px; }
  /* line 243, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .news__content h2 + h3 {
    margin-bottom: 1em; }
  /* line 246, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .news__content h2 + .news__figure {
    margin-top: 1em; }
  /* line 250, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .news__content .news__figure {
    margin-bottom: 1em;
    display: block;
    text-align: center; }

/* line 258, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.item--news .news__content {
  position: relative;
  text-align: left;
  margin: auto; }

@media (min-width: 800px) {
  /* line 264, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .item--news .row {
    display: flex;
    align-items: center; }
  /* line 268, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .item--news .item__element:not(.element--image) {
    display: flex;
    align-items: center; } }

@media (min-width: 800px) {
  /* line 275, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .multi-gallery .flickity-viewport {
    height: 100% !important; } }

/*------------------------------------*  #DESCRIPTION
\*------------------------------------*/
/* line 302, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.description {
  display: block; }

@media (min-width: 800px) {
  /* line 306, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .description__header {
    bottom: -45px; } }

@media (max-width: 799px) {
  /* line 306, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .description__header {
    margin-top: 20px; }
    /* line 312, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
    .with-description .description__header {
      margin-bottom: 30px; } }

@media (min-width: 800px) {
  /* line 318, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 4px);
    height: 100%;
    z-index: 0;
    padding-top: 125px;
    display: flex; } }

/* line 329, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.with-description .content-wrapper {
  z-index: 3;
  background-color: #FFFFFF; }

@media (max-width: 799px) {
  /* line 318, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
  .content-wrapper {
    position: static; } }

/* line 336, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.gallery-wrapper:not(.with-description) .content-wrapper {
  display: none; }

/* line 341, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.description__content {
  max-width: 550px;
  max-height: calc(100vh - 260px);
  overflow: hidden;
  margin: auto; }

/* line 353, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.description__subtitle {
  text-transform: none;
  margin-bottom: .5em; }

/* line 358, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_02-molecules.scss */
.description__button {
  cursor: pointer; }

/*------------------------------------*  #ORGANISMS
\*------------------------------------*/
/*------------------------------------*  #GLOBAL
\*------------------------------------*/
/* line 11, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
.primary-header,
.primary-nav {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #FFFFFF; }
  @media (max-width: 799px) {
    /* line 11, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
    .primary-header,
    .primary-nav {
      padding-top: 15px;
      padding-bottom: 15px; } }
  /* line 20, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
  .primary-header a,
  .primary-nav a {
    border: none; }
    /* line 22, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
    .primary-header a:hover,
    .primary-nav a:hover {
      border-bottom: 1px solid #000; }

/**
 * global > header
 */
/* line 33, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
.primary-header h1 {
  text-transform: none;
  text-align: center; }
  /* line 36, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
  .primary-header h1 a {
    text-transform: uppercase;
    margin-right: .1em;
    border-bottom: 1px solid #000; }
    /* line 40, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
    .primary-header h1 a:hover {
      border-bottom: 1px solid transparent; }

/**
 * global > navigation
 */
/* line 52, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
.primary-nav {
  position: relative; }
  /* line 54, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
  .primary-nav ul {
    text-align: center; }
  /* line 57, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
  .primary-nav li {
    display: inline-block; }
    /* line 59, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
    .primary-nav li:not(:last-of-type) {
      margin-right: 1em; }
  /* line 63, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
  .primary-nav .extra-link {
    position: absolute;
    top: 0; }
    @media (max-width: 799px) {
      /* line 63, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
      .primary-nav .extra-link {
        display: none; } }
    /* line 69, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
    .primary-nav .extra-link.link--left {
      left: 1.5%; }
    /* line 72, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
    .primary-nav .extra-link.link--right {
      right: 1.5%; }

/* line 79, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
nav li.current-menu-item a,
nav li.current_page_item a {
  border-bottom: 1px solid #000; }

/* line 85, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
.post-type-archive-news .menu-item-66 a {
  border-bottom: 1px solid #000; }

/*------------------------------------*  #SECTIONS
\*------------------------------------*/
/**
 * sections > projects
 */
/* line 98, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
.project-nav {
  display: none; }

/**
 * sections > news
 */
/* line 106, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
article.news {
  margin-bottom: 100px; }
  /* line 108, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
  article.news time {
    display: block; }
    @media (max-width: 799px) {
      /* line 108, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
      article.news time {
        margin-top: 20px;
        width: 330px;
        max-width: 100%;
        margin: auto; } }
  @media (min-width: 800px) {
    /* line 117, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
    article.news .grid__col:first-of-type {
      text-align: right; } }
  /* line 122, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
  article.news img {
    max-height: 50vh; }

/* line 127, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
.about__content,
.news__content {
  width: 330px;
  max-width: 100%;
  margin: auto; }

/**
 * sections > about
 */
/* line 139, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
article.about {
  margin-bottom: 30px; }
  /* line 140, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
  article.about h2,
  article.about h3 {
    text-transform: none;
    text-indent: 32px; }
  /* line 146, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
  article.about .about__title {
    margin-bottom: .5em; }

/* line 151, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
.about-aside {
  position: relative; }
  @media (min-width: 1100px) {
    /* line 151, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
    .about-aside {
      z-index: 99999; } }

/* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
.about-nav {
  position: fixed;
  z-index: 2; }
  /* line 160, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
  .about-nav a {
    border: none;
    display: inline-block;
    border-bottom: 1px solid transparent; }
    /* line 164, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
    .about-nav a:hover {
      border-bottom: 1px solid #000; }
  @media (max-width: 799px) {
    /* line 159, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
    .about-nav {
      max-width: 87%;
      top: 48px;
      width: 330px;
      left: 50%;
      transform: translateX(-50%); }
      /* line 176, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
      .about-nav .nav-tabs {
        text-align: center; }
      /* line 179, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
      .about-nav li {
        display: inline-block; } }
  /* line 183, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
  .about-nav li.active a {
    border-bottom: 1px solid #000; }

/* line 189, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
.about-contact a {
  display: inline;
  border-bottom: 1px solid transparent; }
  /* line 192, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
  .about-contact a:hover {
    border-bottom: 1px solid #000; }

@media (min-width: 800px) {
  /* line 188, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
  .about-contact {
    position: fixed;
    bottom: 30px;
    z-index: 9999; } }

@media (max-width: 799px) {
  /* line 188, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_03-organisms.scss */
  .about-contact {
    width: 330px;
    max-width: 100%;
    margin: auto;
    margin-bottom: 30px; } }

/*------------------------------------*  #TEMPLATES
\*------------------------------------*/
/* line 6, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
.container-fluid {
  padding-top: 100px;
  padding-bottom: 60px; }
  @media (max-width: 799px) {
    /* line 6, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
    .container-fluid {
      padding-bottom: 60px; } }
  /* line 12, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
  .home .container-fluid,
  .projects .container-fluid {
    padding-top: 0;
    padding-bottom: 150px; }
    @media (max-width: 799px) {
      /* line 12, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
      .home .container-fluid,
      .projects .container-fluid {
        padding-bottom: 40px; } }
  @media (max-width: 799px) {
    /* line 6, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
    .container-fluid {
      padding-bottom: 80px; } }
  /* line 23, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
  .container-fluid .primary-header,
  .container-fluid .primary-nav {
    position: fixed;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%); }
  /* line 30, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
  .container-fluid .primary-header {
    top: 0; }
  /* line 33, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
  .container-fluid .primary-nav {
    bottom: 0; }

/* line 38, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
.wrapper,
.primary-header,
.primary-nav {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%; }

/* line 46, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
.primary-nav,
.primary-header {
  padding-left: 6.5%;
  padding-right: 6.5%; }

/*------------------------------------*  #HOMEPAGE
\*------------------------------------*/
/* line 56, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
body.home {
  overflow: hidden; }

/*------------------------------------*  #ABOUT&NEWS
\*------------------------------------*/
@keyframes fromWhite {
  0% {
    background-color: #FFFFFF; }
  100% {
    background-color: #CCC; } }

@keyframes fromGrey {
  0% {
    background-color: #CCC; }
  100% {
    background-color: #FFFFFF; } }

/* line 86, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
.wrapper.news,
.wrapper.news .primary-header,
.wrapper.news .primary-nav,
.wrapper.news .about-nav,
body .wrapper.news,
.wrapper.about,
.wrapper.about .primary-header,
.wrapper.about .primary-nav,
.wrapper.about .about-nav,
body
.wrapper.about {
  background-color: #CCC; }

/* line 93, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
.wrapper.news img,
.wrapper.about img {
  mix-blend-mode: multiply; }

/* line 100, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
.wrapper.news.from-white,
.wrapper.news.from-white .primary-header,
.wrapper.news.from-white .primary-nav,
.wrapper.news.from-white .about-nav,
body .wrapper.news.from-white,
.wrapper.about.from-white,
.wrapper.about.from-white .primary-header,
.wrapper.about.from-white .primary-nav,
.wrapper.about.from-white .about-nav,
body
.wrapper.about.from-white {
  animation: fromWhite 1s ease-in-out 1; }

@media (max-width: 799px) {
  /* line 109, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
  .wrapper.about {
    padding-top: 20px; } }

/* line 117, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
.wrapper.projects.from-grey,
.wrapper.projects.from-grey .primary-header,
.wrapper.projects.from-grey .primary-nav,
body .wrapper.projects.from-grey,
.wrapper.home.from-grey,
.wrapper.home.from-grey .primary-header,
.wrapper.home.from-grey .primary-nav,
body
.wrapper.home.from-grey {
  animation: fromGrey 1s ease-in-out 1; }

/* line 125, /Users/antoine/Repo/dev-officekgdvs/assets/css/modules/_04-templates.scss */
.about__content figure {
  margin: 1em 0;
  text-align: center; }

/*------------------------------------*    #BASE-STYLES
\*------------------------------------*/
/* line 31, /Users/antoine/Repo/dev-officekgdvs/assets/css/screen.scss */
html {
  font-size: 100%; }
  @media screen and (max-width: 767px) {
    /* line 31, /Users/antoine/Repo/dev-officekgdvs/assets/css/screen.scss */
    html {
      -webkit-text-size-adjust: 100%; } }

/* line 38, /Users/antoine/Repo/dev-officekgdvs/assets/css/screen.scss */
body {
  background: #FFFFFF;
  color: #000;
  text-rendering: optimizeLegibility;
  overflow-y: scroll;
  overflow-x: hidden; }
  /* line 47, /Users/antoine/Repo/dev-officekgdvs/assets/css/screen.scss */
  body ::-moz-selection {
    background: rgba(0, 0, 0, 0.2); }
  /* line 48, /Users/antoine/Repo/dev-officekgdvs/assets/css/screen.scss */
  body ::selection {
    background: rgba(0, 0, 0, 0.2); }

/*------------------------------------*    #PRINT
\*------------------------------------*/
@media print {
  /* line 59, /Users/antoine/Repo/dev-officekgdvs/assets/css/screen.scss */
  body {
    font-size: 62.5%; } }
