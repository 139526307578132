/*------------------------------------*\
  #MOLECULES
\*------------------------------------*/



/*------------------------------------*\
  #ITEM
\*------------------------------------*/

.multi-gallery,
.gallery-double {
  position: relative;
  padding-top: 125px;
  @media (max-width: $screen-xs-max) {
    padding-top: 40px;
    @include padding-bottom($spacing-md-responsive);
    @include margin-bottom($spacing-md-responsive);
  }
  @media (max-width: $screen-xs-max) {
    border-bottom: $border-width-sm solid $base-color;
  }
}

.gallery-double {
  border: none;
  @media (max-width: $screen-xs-max) {
    border: none;
    padding-top: 50px;
  }
  .flickity-viewport {
    overflow: visible;
  }
}

.multi-gallery .gallery,
.gallery-double .item {
  @media (min-width: $screen-sm-min) {
    height: calc(100vh - 90px - 75px - 28px * 3);
  }
}

.description__header,
.item__link {
  @media (min-width: $screen-sm-min) {
    position: absolute;
    bottom: -45px;
    z-index: 9;
    left: 50%;
    transform: translateX(-50%);
    padding: .3em .3em 0 .3em;
  }
  text-align: center;
}

.multi-gallery img,
.gallery-double .item .row {
  @media (min-width: $screen-sm-min) {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    height: auto;
    // max-width: 100%;
    max-height: 100%;
  }
  @media (max-width: $screen-xs-max) {
    height: auto;
  }
}

.gallery-double .item .row {
  height: 100%;
  @media (min-width: $screen-sm-min) {
    display: flex;
    align-items: center;
  }
}


.gallery {
  position: relative;
  .multi-gallery & {
    text-align: center;
    @media (max-width: $screen-xs-max) {
      padding-bottom: $spacing-sm; // pour laisser la place au status
      @include margin-bottom($spacing-sm-responsive);
    }
  }
  @media (max-width: $screen-xs-max) {
    .item {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.gallery-double .gallery:not(.single) .element--image,
.multi-gallery .gallery:not(.single) .item {
  cursor: pointer;
}

// lazyload
.gallery-wrapper:not(.gallery-double) .item img {
  transition: opacity .3s ease-in-out;
  &.lazy-loaded {
    opacity: 1;
  }
  &:not(.lazy-loaded) {
    opacity: 0;
  }
}
@media (min-width: $screen-sm-min) {
  .news__figure img {
    transition: opacity .3s ease-in-out;
    &.lazy-loaded {
      opacity: 1;
    }
    &:not(.lazy-loaded) {
      opacity: 0;
    }
  }
}

/**
 * item > multi-gallery
 */

.multi-gallery {
  & > .row {
    position: relative;
  }
}

.multi-gallery .item {
  overflow: hidden;
  @media (max-width: $screen-xs-max) {
    @include padding-bottom($spacing-xs-responsive);
    height: calc(100vh - 220px);
    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  @media (min-width: $screen-sm-min) {
    width: 100%;
    height: 100%;
  }
}

.gallery-double {
  @media (min-width: 500px) and (max-width: $screen-sm-max) {
    .item > .row {
      display: flex;
    }
    .item__element {
      width: 50%;
      height: calc(100vh - 140px) !important;
      img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

/**
 * item > gallery-double
 */

.gallery-double .item {
  width: 100%;
  cursor: auto;
  .item__element {
    text-align: center;
    position: relative;
    height: 100%;
    @media (max-width: $screen-xs-max) {
      padding-bottom: $spacing-xs;
      height: calc(50vh - 100px);
      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
  }
  img {
    @media (min-width: $screen-sm-min) {
      max-width: 100%;
      width: auto;
      max-height: 100%;
      height: auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .item__link {
    .link__button {
      cursor: pointer;
      display: inline-block;
    }
  }
  h3 + p{
    margin-top: 0
  }
  .news__content h2 + h3{
    margin-bottom: 0;
  }
}

@media (max-width: $screen-xs-max) {
  .flickity-slider {
    display: flex;
    align-items: center;
  }
}

.gallery-status {
  @include font-size($font-sizes-md);
  position: absolute;
  bottom: -25px;
  @media (min-width: $screen-sm-min) {
    .gallery--first & {
      left: $grid-gutter-width;
    }
    .gallery--last & {
      right: $grid-gutter-width;
    }
  }
}

.news__content {
  h2 + h3 {
    margin-bottom: 1em;
  }
  h2 + .news__figure{
    margin-top: 1em;
  }
  max-width: 500px;
  .news__figure {
    margin-bottom: 1em;
    display: block;
    text-align: center;
  }
}

.item--news {
  .news__content {
    position: relative;
    text-align: left;
    margin: auto;
  }
  @media (min-width: $screen-sm-min) {
    .row {
      display: flex;
      align-items: center;
    }
    .item__element:not(.element--image) {
      display: flex;
      align-items: center;
    }
  }
}

.multi-gallery .flickity-viewport {
  @media (min-width: $screen-sm-min) {
    height: 100% !important;
  }
}

// .gallery--last,
// .gallery-double .item__element:last-of-type {
//   @media (max-width: $screen-xs-max) {
//     display: none;
//   }
// }

// .gallery-wrapper .item img {
//   opacity: 0;
//   transition: opacity .3s ease-in-out;
//   max-width: 500px;
//   &.lazy-loaded {
//     opacity: 1;
//   }
// }


/*------------------------------------*\
  #DESCRIPTION
\*------------------------------------*/

.description {
  display: block;
}

.description__header {
  @media (min-width: $screen-sm-min) {
    bottom: -45px;
  }
  @media (max-width: $screen-xs-max) {
    @include margin-top($spacing-sm-responsive);
    .with-description & {
      @include margin-bottom($spacing-md-responsive);
    }
  }
}

.content-wrapper {
  @media (min-width: $screen-sm-min) {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 4px); // pour masquer les éventuels demi pixels qui trainent
    height: 100%;
    z-index: 0;
    padding-top: 125px;
    display: flex;
  }
  .with-description & {
    z-index: 3;
    background-color: $body-color;
  }
  @media (max-width: $screen-xs-max) {
    position: static;
  }
  .gallery-wrapper:not(.with-description) & {
    display: none;
  }
}

.description__content {
  max-width: 550px;
  max-height: calc(100vh - 260px);
  overflow: hidden;
  margin: auto;
  @media (min-width: $screen-sm-min) {
    // position: relative;
    // top: 55%;
    // transform: translateY(-50%);
  }
}

.description__subtitle {
  text-transform: none;
  margin-bottom: .5em;
}

.description__button {
  cursor: pointer;
}