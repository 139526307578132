/*------------------------------------*\
    #GLOBAL TYPOGRAPHY
\*------------------------------------*/

@font-face {
  font-family: 'FolioOffice';
  src: url('../fonts/folio_book-webfont.eot');
  src: url('../fonts/folio_book-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/folio_book-webfont.woff') format('woff'),
       url('../fonts/folio_book-webfont.ttf') format('truetype'),
       url('../fonts/folio_book-webfont.svg#FolioBook') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*------------------------------------*\
    #FONTS
\*------------------------------------*/

.font-body{
  font-family: "FolioOffice", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}


/*------------------------------------*\
    #TYPESIZE/SCALE
\*------------------------------------*/

$font-size-md         : 14px;

//** Unit-less `line-height`
$line-height          : 1.35;
// for use in components like buttons, labels
$line-height-form     : 1.428571429 !default;     // 20/14
$font-res-ratio       : 1.1;                      // responsive ratio



/*------------------------------------*\
    #FONTSIZES
\*------------------------------------*/


$font-sizes-md: (
  null : ($font-size-md, $line-height)
);



/*------------------------------------*\
    #CORE TYPOGRAPHY
\*------------------------------------*/


h1,
h2,
h3,
h4,
h5,
h6 {
  @include font-size($font-sizes-md);
  text-transform: uppercase;
}

h3 + p,
h3 + ul,
ul + p {
  margin-top: .5em;
}
p + h3,
ul + h3 {
    margin-top: 1em;
}

p {
  @include font-size($font-sizes-md);
  margin-bottom: .5em;
}

blockquote p {
  text-align: center;
}

a {
  color: $base-color;
	border-bottom: $border-width-sm solid $action;
	padding-bottom: 1px;
  margin-bottom: 3px;
  text-decoration: none;
  @include font-size($font-sizes-md);
  @include link-active-styles {   // Mixin for interactions (hover,focus,active)
    color: $action;
    border-bottom: $border-width-sm solid transparent;
  }
}

time {
  @include font-size($font-sizes-md);
}

em,
i{
  @extend .font-body;
}

b,
strong{
  @extend .font-body;
}

em > b,
b > em,
i > b,
b > i,
em > strong,
strong > em,
i > strong,
strong > i {
  @extend .font-body;
}

p sub, p sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

p sup {
  top: -0.5em;
}

p sub {
  bottom: -0.25em;
}

p small{
  @include font-size($font-sizes-md);
}

p abbr {
  border-bottom: $border-width-sm dotted $base-color;
  cursor: help;
}

p q,
p cite {
 &:before {
    content:'"';
 }
 &:after {
    content:'"';
 }
}

ul:not(.nav) {
  li {
    position: relative;
    padding-left: 1.4em;
    &:before {
      content: "-";
      position: absolute;
      left: 0;
    }
  }
}

p mark {
  background-color: rgba($action, .2);
  color: $base-color;
}

p code,
p samp {
  font-family: monospace;
  @include font-size($font-sizes-md);
}

pre {
  font-family: monospace;
  @include font-size($font-sizes-md);
}

hr {
  background: $base-color;
  height: $border-width-sm;
  border: none;
  margin: 0;
}

figcaption {
  @include font-size($font-sizes-md);
  @include margin-top($spacing-xs-responsive);
}

dl {
  dt {
    @include font-size($font-sizes-md);
    @include margin-bottom($spacing-xs-responsive);
    &:first-of-type{
      @include padding-top($spacing-sm-responsive);
      border-top: $border-width-sm $base-color solid;
    }
  }
  dd{
    padding-bottom: $spacing-sm;
    margin-bottom: $spacing-sm;
    border-bottom: $border-width-sm $base-color solid;
  }
}

ol, ul, pre, dl                                         {@include font-size($font-sizes-md);}
ul, ol                                                  {@include nobullet;}
ul ul                                                   {@include font-size($font-sizes-md);}
ol ol                                                   {@include font-size($font-sizes-md);}
.text ul                                                {@include unordered; margin-left: 1em;}
.text ol                                                {@include ordered; margin-left: 1em;}
.text > ul,
.text > ol                                              {margin-bottom: 1em;}
li                                                      {line-height: inherit;}
input, textarea, label, select                          {line-height: $line-height-form}
