/*------------------------------------*\
  #ORGANISMS
\*------------------------------------*/



/*------------------------------------*\
  #GLOBAL
\*------------------------------------*/

.primary-header,
.primary-nav {
  @include padding-top($spacing-md-responsive);
  @include padding-bottom($spacing-md-responsive);
  @media (max-width: $screen-xs-max) {
    @include padding-top($spacing-xs-responsive);
    @include padding-bottom($spacing-xs-responsive);
  }
  background-color: $body-color;
  a {
    border: none;
    &:hover {
      border-bottom: $border-width-sm solid $action;
    }
  }
}

/**
 * global > header
 */

.primary-header {
  h1 {
    text-transform: none;
    text-align: center;
    a {
      text-transform: uppercase;
      margin-right: .1em;
      border-bottom: $border-width-sm solid $action;
      &:hover {
        border-bottom: $border-width-sm solid transparent;
      }
    }
  }
}


/**
 * global > navigation
 */

.primary-nav {
  position: relative;
  ul {
    text-align: center;
  }
  li {
    display: inline-block;
    &:not(:last-of-type) {
      margin-right: 1em;
    }
  }
  .extra-link {
    @media (max-width: $screen-xs-max) {
      display: none;
    }
    position: absolute;
    top: 0;
    &.link--left {
      left: $grid-gutter-width/2;
    }
    &.link--right {
      right: $grid-gutter-width/2;
    }
  }
}

nav li {
  &.current-menu-item a,
  &.current_page_item a{
    border-bottom: $border-width-sm solid $action;
  }
}

.post-type-archive-news .menu-item-66 a{
  border-bottom: $border-width-sm solid $action;
}

/*------------------------------------*\
  #SECTIONS
\*------------------------------------*/


/**
 * sections > projects
 */

.project-nav{
  display: none;
}

/**
 * sections > news
 */

article.news {
  @include margin-bottom($spacing-xxxl-responsive);
  time {
    display: block;
    @media (max-width: $screen-xs-max) {
      @include margin-top($spacing-sm-responsive);
        width: 330px;
        max-width: 100%;
        margin: auto;
    }
  }
  .grid__col:first-of-type {
    @media (min-width: $screen-sm-min) {
      text-align: right;
    }
  }
  img {
    max-height: 50vh;
  }
}

.about__content,
.news__content {
  width: 330px;
  max-width: 100%;
  margin: auto;
}


/**
 * sections > about
 */

article.about {
  h2,
  h3{
    text-transform: none;
    text-indent: 32px;
  }
  @include margin-bottom($spacing-md-responsive);
  .about__title {
    margin-bottom: .5em;
  }
}

.about-aside {
  position: relative;
  // pour IE
  @media (min-width: $screen-md-min) {
    z-index: 99999;
  }
}

.about-nav {
  a {
    border: none;
    display: inline-block;
    border-bottom: $border-width-sm solid transparent;
    &:hover {
      border-bottom: $border-width-sm solid $action;
    }
  }
  position: fixed;
  z-index: 2;
  @media (max-width: $screen-xs-max) {
    max-width: 87%;
    top: 48px;
    width: 330px;
    left: 50%;
    transform: translateX(-50%);
    .nav-tabs {
      text-align: center;
    }
    li {
      display: inline-block;
    }
  }
  li.active a {
    border-bottom: $border-width-sm solid $action;
  }
}

.about-contact {
  a {
    display: inline;
    border-bottom: $border-width-sm solid transparent;
    &:hover {
      border-bottom: $border-width-sm solid $action;
    }
  }
  @media (min-width: $screen-sm-min) {
    position: fixed;
    bottom: 30px;
    z-index: 9999;
  }
  @media (max-width: $screen-xs-max) {
    width: 330px;
    max-width: 100%;
    margin: auto;
    @include margin-bottom($spacing-md-responsive);
  }
}
