// ------------------------------------------------------------
// Mixins
// ------------------------------------------------------------

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Single side border-radius

@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
   border-top-left-radius: $radius;
}
@mixin border-right-radius($radius) {
  border-bottom-right-radius: $radius;
     border-top-right-radius: $radius;
}
@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
   border-bottom-left-radius: $radius;
}
@mixin border-left-radius($radius) {
  border-bottom-left-radius: $radius;
     border-top-left-radius: $radius;
}

// Opacity

@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: alpha(opacity=$opacity-ie);
}

// ------------------------------------------------------------
// calculate px and percent
// ------------------------------------------------------------

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}


// -------------------------------------------------------
// List styles
// -------------------------------------------------------

// Mixins for list style types
@mixin unordered {
   list-style-position: outside;
   list-style-type: disc;
}

@mixin unordered-inside {
   list-style-position: inside;
   list-style-type: disc;
}

@mixin ordered {
  list-style-position: outside;
  list-style-type: decimal;
}

@mixin ordered-inside {
  list-style-position: inside;
  list-style-type: decimal;
}

@mixin nobullet {
  list-style-type: none;
}

// -------------------------------------------------------
// Transitions
// -------------------------------------------------------

@mixin _transition($prop:all, $duration:0.2s, $timing:ease-in-out, $delay:0s) {
  @include transition($prop $duration $timing $delay);
}



// -------------------------------------------------------
// Link hover styles
// -------------------------------------------------------

@mixin link-active-styles {
  &:hover, &:focus, &:active {
    @content;
  }
}


// -------------------------------------------------------
// Align things vertically (http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/)
// -------------------------------------------------------

@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}


// -------------------------------------------------------
// Media queries
// -------------------------------------------------------


// Reference widths from settings file
@mixin respond-to($media-min, $IE9: true) {
    @if $IE9 == true {
        .lt-ie9 & {
            @content;
        }
        @media screen and (min-width: $media-min)  {
            @content;
        }
    }
    @else {
        @media screen and (min-width: $media-min) {
            @content;
        }
    }
}

@mixin respond-to-max($media-max, $IE9: true) {
    @if $IE9 == true {
        .lt-ie9 & {
            @content;
        }
        @media screen and (max-width: $media-max - 1px)  {
            @content;
        }
    }
    @else {
        @media screen and (max-width: $media-max - 1px) {
            @content;
        }
    }
}


@mixin respond-to-min-max($media-min, $media-max, $IE9: true) {
    @if $IE9 == true {
        .lt-ie9 & {
            @content;
        }
        @media screen and (min-width: $media-min) and (max-width: $media-max)  {
            @content;
        }
    }
    @else {
         @media screen and (min-width: $media-min) and (max-width: $media-max) {
            @content;
        }
    }
}


// -------------------------------------------------------
// Retina images
// -------------------------------------------------------

// Usage: Send path, image name, and width and height.
// Name retina images [name]2x.

@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}

// -------------------------------------------------------
// HEX to RGB
// -------------------------------------------------------

// http://codepen.io/sturobson/pen/hdKyv
@mixin rgba($color, $value) {
  background-color: $color;
  background-color: rgba($color, $value);
}


// -------------------------------------------------------
// Organizing Font Sizes With Sass Maps
// -------------------------------------------------------
// http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/


// FONT SIZE

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      @include make-font-size($fs-font-size);
    }
    @else {
      // If $fs-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
  // If $fs-font-size is a list, include
  // both font-size and line-height
  @if type-of($fs-font-size) == "list" {
    font-size: nth($fs-font-size, 1);
    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  }
  @else {
    font-size: $fs-font-size;
  }
}


// MARGIN BOTTOM

@mixin margin-bottom($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-margin-bottom in $fs-map {
    @if $fs-breakpoint == null {
      @include make-margin-bottom($fs-margin-bottom);
    }
    @else {
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-margin-bottom($fs-margin-bottom);
      }
    }
  }
}

// Utility function for mixin margin-bottom

@mixin make-margin-bottom($fs-margin-bottom) {
  margin-bottom: $fs-margin-bottom;
}


// MARGIN TOP

@mixin margin-top($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-margin-top in $fs-map {
    @if $fs-breakpoint == null {
      @include make-margin-top($fs-margin-top);
    }
    @else {
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-margin-top($fs-margin-top);
      }
    }
  }
}

// Utility function for mixin margin-bottom

@mixin make-margin-top($fs-margin-top) {
  margin-top: $fs-margin-top;
}


// PADDING-BOTTOM

@mixin padding-bottom($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-padding-bottom in $fs-map {
    @if $fs-breakpoint == null {
      @include make-padding-bottom($fs-padding-bottom);
    }
    @else {
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-padding-bottom($fs-padding-bottom);
      }
    }
  }
}

// Utility function for mixin padding-bottom

@mixin make-padding-bottom($fs-padding-bottom) {
  padding-bottom: $fs-padding-bottom;
}

// PADDING-TOP

@mixin padding-top($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-padding-top in $fs-map {
    @if $fs-breakpoint == null {
      @include make-padding-top($fs-padding-top);
    }
    @else {
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-padding-top($fs-padding-top);
      }
    }
  }
}

// Utility function for mixin padding-top

@mixin make-padding-top($fs-padding-top) {
  padding-top: $fs-padding-top;
}


// BOTTOM

@mixin bottom($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-bottom in $fs-map {
    @if $fs-breakpoint == null {
      @include make-bottom($fs-bottom);
    }
    @else {
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-bottom($fs-bottom);
      }
    }
  }
}

// Utility function for mixin bottom

@mixin make-bottom($fs-bottom) {
  bottom: $fs-bottom;
}


// top

@mixin top($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-top in $fs-map {
    @if $fs-breakpoint == null {
      @include make-top($fs-top);
    }
    @else {
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-top($fs-top);
      }
    }
  }
}

// Utility function for mixin top

@mixin make-top($fs-top) {
  top: $fs-top;
}
