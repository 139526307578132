// ------------------------------------------------------------
// Settings

// :: Colours
// :: Breakpoints
// :: Typography
// :: Borders
// :: Spacing

// ------------------------------------------------------------



/*------------------------------------*\
    #COLOURS
\*------------------------------------*/


// Main colour palette
$black            : #000;
$white            : #FFFFFF;
$grey             : #CCC; // used in forms
$light-grey       : #e0e0e0; // used in forms
$light-blue       : #9ACCE2; // used in forms


// Assign colours to variable roles
// Be descriptive without being specific.
// This allows for consistency project to project, and easy changing

$body-color       : $white;
$base-color       : $black;
$action           : $black;



/*------------------------------------*\
    #BREAKPOINTS
\*------------------------------------*/


// Extra small screen / phone
$screen-xs:                  600px !default;
$screen-xs-min:              $screen-xs !default;

// Small screen / tablet
$screen-sm:                  800px !default;
$screen-sm-min:              $screen-sm !default;

// Medium screen / desktop
$screen-md:                  1100px !default;
$screen-md-min:              $screen-md !default;

// Large screen / wide desktop
$screen-lg:                  1500px !default;
$screen-lg-min:              $screen-lg !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         3% !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             calc(720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            calc(940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      calc(1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;


// mediaqueries for spaces & typography
$breakpoints: (
  sm   : $screen-xs-max,
  md   : $screen-sm-max,
  lg   : $screen-md-max,
);


/*------------------------------------*\
    #SPACES
\*------------------------------------*/


$spacing-base     : 5px;

$spacing-xxxl     : $spacing-base * 20;  // 100px
$spacing-xxl      : $spacing-base * 16;  // 80px
$spacing-xl       : $spacing-base * 12;  // 60px
$spacing-lg       : $spacing-base * 8;   // 40px
$spacing-md       : $spacing-base * 6;   // 30px
$spacing-sm       : $spacing-base * 4;   // 20px
$spacing-xs       : $spacing-base * 3;   // 15px
$spacing-xxs      : $spacing-base * 2;   // 10px

$spacing-res-ratio : 1.1;

$spacing-xxxl-responsive: (
  null : $spacing-xxxl
);

$spacing-xxl-responsive: (
  null : $spacing-xxl
);

$spacing-xl-responsive: (
  null : $spacing-xl
);

$spacing-lg-responsive: (
  null : $spacing-lg
);

$spacing-md-responsive: (
  null : $spacing-md
);

$spacing-sm-responsive: (
  null : $spacing-sm
);

$spacing-xs-responsive: (
  null : $spacing-xs
);

$spacing-xxs-responsive: (
  null : $spacing-xxs
);


/*------------------------------------*\
    #BORDERS WIDTH
\*------------------------------------*/


$border-width-sm         : 1px;
$border-width-md         : 3px;
