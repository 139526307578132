/*------------------------------------*\
    #IMPORTS PRE_RENDER — Must be after reset
\*------------------------------------*/

@import "libs/reset";
@import "libs/settings";
@import "libs/mixins";
@import "libs/grid-mixins";

@import "libs/typography";            // Base level typography
@import "libs/forms";                 // Forms
@import "libs/grid";                  // Grid system

// Use SASS globbing to import all _.scss files in the module folder.
// These should be independent modules that follow the BEM/SMACSS way

@import "modules/_00-00-temp-patternlab.scss";
@import "modules/_00-01-flickity.scss";
@import "modules/_01-atoms.scss";
@import "modules/_02-molecules.scss";
@import "modules/_03-organisms.scss";
@import "modules/_04-templates.scss";



/*------------------------------------*\
    #BASE-STYLES
\*------------------------------------*/


html {
  font-size: 100%;
  @include respond-to-max(768px, $IE9: false) { // Stop at precise width, as we're talking about devices
    -webkit-text-size-adjust: 100%; // Stops text scaling in iOS.
  }
}

body {
  @extend .font-body;
  background: $body-color;
  color: $base-color;
  text-rendering: optimizeLegibility;
  overflow-y: scroll;
  overflow-x: hidden;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  ::-moz-selection { background: rgba($base-color, .2); }
  ::selection { background: rgba($base-color, .2); }
}



/*------------------------------------*\
    #PRINT
\*------------------------------------*/


@media print {
  body {
    font-size: percentage(10 / 16);
  }
}
