/*------------------------------------*\
  #TEMPLATES
\*------------------------------------*/


.container-fluid {
  @include padding-top($spacing-xxxl-responsive);
  @include padding-bottom($spacing-xl-responsive);
  @media (max-width: $screen-xs-max) {
    @include padding-bottom($spacing-xl-responsive);
  }
  .home &,
  .projects & {
    padding-top: 0;
    padding-bottom: 150px;
    @media (max-width: $screen-xs-max) {
      @include padding-bottom($spacing-lg-responsive);
    }
  }
  @media (max-width: $screen-xs-max) {
    @include padding-bottom($spacing-xxl-responsive);
  }
  .primary-header,
  .primary-nav {
    position: fixed;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
  }
  .primary-header {
    top: 0;
  }
  .primary-nav {
    bottom: 0;
  }
}

.wrapper,
.primary-header,
.primary-nav {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
}

.primary-nav,
.primary-header {
  padding-left: 6.5%;
  padding-right: 6.5%;
}

/*------------------------------------*\
  #HOMEPAGE
\*------------------------------------*/

body.home {
  overflow: hidden;
}

/*------------------------------------*\
  #ABOUT&NEWS
\*------------------------------------*/

@keyframes fromWhite {
  0%   { background-color: $body-color; }
  100% { background-color: $grey; }
}

@keyframes fromGrey {
  0%   { background-color: $grey; }
  100% { background-color: $body-color; }
}

// .wrapper.home,
// .wrapper.projects {
//   &,
//   .primary-header,
//   .primary-nav,
//   body & {
//     background-color: $body-color;
//   }
// }

.wrapper.news,
.wrapper.about {
  &,
  .primary-header,
  .primary-nav,
  .about-nav,
  body & {
    background-color: $grey;
  }
  img {
    mix-blend-mode: multiply;
  }
}

.wrapper.news.from-white,
.wrapper.about.from-white{
  &,
  .primary-header,
  .primary-nav,
  .about-nav,
  body & {
    animation: fromWhite 1s ease-in-out 1;
  }
}

.wrapper.about {
  @media (max-width: $screen-xs-max) {
    @include padding-top($spacing-sm-responsive);
  }
}

.wrapper.projects.from-grey,
.wrapper.home.from-grey{
  &,
  .primary-header,
  .primary-nav,
  body & {
    animation: fromGrey 1s ease-in-out 1;
  }
}

.about__content figure {
  margin: 1em 0;
  text-align: center;
}
