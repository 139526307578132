// ------------------------------------------------------------
// Forms
// ------------------------------------------------------------


label, legend, select {
	display: block;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

textarea {
	overflow: auto;
	vertical-align: top;
}


input[type="radio"],
input[type="checkbox"] {
	margin: 4px 0 0;
	margin-top: 1px \9;
	*margin-top: 0;
	line-height: normal;
	cursor: pointer;
}

input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
	width: auto;
}

select,
input[type="file"] {
	*margin-top: 4px; /* In IE7, the height of the select element cannot be changed by height, only font-size */
}

select:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	outline: thin dotted #333;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}


.form-control {
	transition: border-color 0.3s;
	width: 100%;
	border: 1px solid $light-grey;
	border-radius: 2px;
	box-shadow: none;
	padding: 6px;
	&:focus {
		border-color: $light-blue;
		box-shadow: none;
	}
	&:hover{
		border-color: $grey;
	}
}

select.form-control{
	appearance: none;
  transition: border-color 0.3s;
  border: 1px solid $light-grey;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  vertical-align: middle;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE1LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iMCAwIDIwIDIwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMCAyMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+Cjxwb2x5Z29uIHBvaW50cz0iMTAsMTEuMiA2LjQsNy42IDQuMyw3LjYgMTAsMTMuMyAxNS43LDcuNiAxMy42LDcuNiAiLz4KPC9zdmc+Cg==);
  background-position: right 7px center;
  background-repeat: no-repeat;
  background-size: auto 16px;
  max-width: 100%;
  &:hover {
		box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.16);
		border-color: #999999;
  }
}

label{
	font-size: $font-size-md;
	font-weight: 400;
	text-transform: uppercase;
	.checkbox &, .radio &{
		font-size: $font-size-md;
		line-height: 1.7;
	}
}


/*------------------------------------*\
  #FORMS
\*------------------------------------*/


.form {
  legend {
    @extend .font-body;
    @include font-size($font-sizes-md);
    text-align: center;
    color: $base-color;
    border: none;
    @include margin-bottom($spacing-xs-responsive);
  }
  .inline-container {
    @media (min-width: $screen-sm-min){
      display: flex;
      align-items: stretch;
    }
  }
  input {
    flex: 1 auto;
    height: 100%;
    border: $border-width-sm solid $base-color;
    padding: $spacing-xs;
    border-radius: 0;
    @include font-size($font-sizes-md);
    &:focus{
      outline: none;
			border: $border-width-sm solid $base-color;
    }
		&:hover {
			border: $border-width-sm solid $base-color;
		}
    @media (max-width: $screen-xs-max){
      width: 100%;
    }
  }
  .button {
    @include font-size($font-sizes-md);
    white-space: nowrap;
    min-width: 150px;
    @media (max-width: $screen-xs-max){
      width: 100%;
      margin-top: -$border-width-sm;
    }
    @media (min-width: $screen-sm-min){
      margin-left: -$border-width-sm;
    }
  }
}
